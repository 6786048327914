
import { Routes, Route } from "react-router-dom";
import './App.css';
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./pages/Footer";
import PhotoGallery from "./pages/Gallery";
import HeaderMenu from './pages/HeaderMenu';
import Home from './pages/Home';
import VideoDetails from "./pages/VideoDetails";
import Wellness from "./pages/Wellness";
import TestimonialPage from "./pages/TestimonialPage";
import CmsPage from "./pages/Cms";
function App() {
  return (
    <div className="App">
      <HeaderMenu />
      <Routes>
          <Route exact={`true`} path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/photo-gallery" element={<PhotoGallery />} />
          <Route path="/wllness" element={<Wellness />} />
          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/video-detail/:videoId" element={<VideoDetails />} />
          <Route path="/page/:pageName"  exact={`true`} element={<CmsPage />} />
          
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

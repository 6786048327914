import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Globals from '../../common/Globals';
import addBanner from '../../assets/images/add.png';
import VideoItem from '../VideoItem';
export default function LatestHomeVideo() {
    const [videos, setVideos] = useState([]);
    const [ads, setAds] = useState([])
    useEffect(() => {
        getVideos();
    }, [])
    const getVideos = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.LATEST_VIDEO_URL, {}, {})
            .then(function (response) {
                // console.log(response.data.latestVideos);
                setVideos(response.data.latestVideos);
                setAds([{'ads_position':response.data.ads_position,'ads_image':response.data.ads_image,'ads_image_url':response.data.ads_image_url}])
                //console.log(ads);
            })
    }
    return (
        <>
            {
                (videos.length > 0 && ads[0].ads_image_url!=='' && ads[0].ads_position==='top') ?
                    <div>
                        <div className='text-center d-none d-lg-block mt-4'><img src={ads[0].ads_image_url} alt='' /></div>
                    </div> : ''
            }
            <Row>
                {
                    videos.length > 0 ?
                        <>
                            {
                                videos.filter((i, index) => (index < 3)).map((v, pop) => {
                                    // console.log(v);
                                    return (
                                        <Col md="4" className='mb-4' key={pop}>
                                            <VideoItem video={v} />
                                        </Col>
                                    );
                                })}
                        </> : ""
                }
            </Row>
            {
                (videos.length > 0 && ads[0].ads_image_url!=='' && (ads[0].ads_position==='bottom' || ads[0].ads_position==='default')) ?
                    <div>
                        <div className='text-center d-none d-lg-block mt-4'><img src={ads[0].ads_image_url} alt='' /></div>
                    </div> : ''
            }
        </>

    );
}

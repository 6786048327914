import React, { useEffect, useState } from 'react';
import hand from '../../assets/images/hand.png';
import videoIcon from '../../assets/images/play-button.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Globals from '../../common/Globals';
import { Spinner } from 'react-bootstrap';

export default function AboutHome() {
    const [aboutContent, setAboutContent] = useState('');
    const [showLoading, setshowLoading] = useState(true);
    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.CONTENT_URL + '/15', {},
            {})
            .then(function (response) {
                // console.log(response.data);
                setshowLoading(false);
                if (response.data.status === 'ok') {
                    var count = Object.keys(response.data.cms).length;
                    if (count) {
                        setAboutContent(response.data.cms.pagecontent);
                    }
                }
            })
    }
    return (
        <> {showLoading ? <div className='text-center'><Spinner animation="border" className='text-center' variant="primary" /></div> : <div className='AboutmeM'>
            <h1>Hello Friends! We Are  <img src={hand} alt="" /> <span>Kaashika Wellness </span> </h1>
            <div dangerouslySetInnerHTML={{ __html: aboutContent.substring(0, 600) }}></div>
            <div className='d-flex mt-4'>
                <Link to="/about-us" className='myBtn'>Read More</Link>
                <Link to="https://www.youtube.com/@Kaashika_Wellness" target="_blank" className="banner-play-icon" title="Play Video"><img src={videoIcon} alt="" /></Link>
            </div>
        </div>}

        </>
    );
}

import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner  } from 'react-bootstrap';
import Globals from '../../common/Globals';
import axios from 'axios';
export default function HomeBanner() {
    // const [showError, setpShowError] = useState(false);
    const [bannerVideos, setBannerVideos] = useState([]);
    const [showLoading, setShowLoading] = useState(true)
    const [videoCount, setVideoCount] = useState(0)
    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.HOME_BANNER_URL, {},
            {})
            .then(function (response) {
                setShowLoading(false);
                if (response.data.status === 'ok') {
                    var count = Object.keys(response.data.bannerVideos).length;
                    if (count) {
                        setBannerVideos(response.data.bannerVideos);
                        setVideoCount(response.data.bannerVideos.length);
                    }
                }
            })
    }
    return (
        <>
        {showLoading? 
        <div className='text-center'><Spinner animation="border" className='text-center' variant="primary" /></div>
            :videoCount > 0 ?
                <div>
                    <div className='viframe'>
                        <iframe src={bannerVideos[0]['youtube_embed_url']} title={bannerVideos[0]['name']} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    <Row className='viframe2 mt-2'>
                        {videoCount > 1 ?
                            <Col ms="6"><iframe src={bannerVideos[1]['youtube_embed_url']} title={bannerVideos[1]['name']} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></Col>
                            : ''}
                        {videoCount > 2 ?
                            <Col ms="6"><iframe src={bannerVideos[2]['youtube_embed_url']} title={bannerVideos[2]['name']} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></Col>
                            : ''}
                    </Row>
                </div>
                : ''}
        </>
    );
}

import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form, Col, Row, Spinner } from 'react-bootstrap';
import Globals from '../common/Globals';

export default function SubscribeNewsletter() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [showLoading, setshowLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const submitSubcriptionForm = (event) => {
        
        event.preventDefault(); // 👈️ prevent page refresh
        setshowLoading(true);
        setDisableButton(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile", mobile);
        //console.log(formData);
        axios.post(Globals.BASE_URL + '' + Globals.SUBSCRIBE_NEWSLETTER_URL, formData)
            .then((res) => {
                setshowLoading(false);
                setDisableButton(false);
                // console.log(res);
                setNameError(res.data.error.name);
                setMobileError(res.data.error.mobile);
                setEmailError(res.data.error.email);
                if(res.data.actionstatus==='success'){
                    alert(res.data.message);
                    setName('');
                    setEmail('');
                    setMobile('');
                }
                
            })
            .catch((err) => console.log(err));
    };
    return (
        <>
            <h4>Subscribe to our newsletter.</h4>
            <Form>
                <Row>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        <span className='text-danger'>{nameError}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
                        <Form.Control type="tel" placeholder="Mobile No." value={mobile} onChange={(e) => setMobile(e.target.value)} />
                        <span className='text-danger'>{mobileError}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <span className='text-danger'>{emailError}</span>
                    </Form.Group>
                    <Col><Button className='myBtn' onClick={submitSubcriptionForm}  disabled={disableButton}>Submit</Button></Col>
                    <Col><span >{showLoading ?<Spinner animation="border" className='text-center' variant="primary" />:''}</span></Col>
                </Row>
                
                
            </Form>
        </>
    );
}

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container} from 'react-bootstrap';
import Globals from '../common/Globals';
// import Banner from "../assets/images/contact.png"
export default function Contact() {
    const [showError, setpShowError] = useState(false);
    const [cmsTitle, setcmsTitle] = useState('');
    const [pContent, setpContent] = useState('');
    const [cimage, setCimage] = useState('');
    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.CONTENT_URL + '/2', {},
            {})
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status === 'ok') {
                    var count = Object.keys(response.data.cms).length;
                    if (count) {
                        // console.log(response.data.cms.pagename);
                        setcmsTitle(response.data.cms.pageheading);
                        setpContent(response.data.cms.pagecontent);
                        setCimage(response.data.cms.image);
                    } else {
                        setpShowError(true);
                    }
                } else {
                    setpShowError(true);
                }
            })
    }
    document.title = showError ? "404 - Page not found" : cmsTitle;
    document.querySelector("meta[name='description']").setAttribute('content', showError ? "404 - Page not found" : 'About us || Kaashika Wellness');
    document.querySelector("meta[name='keywords']").setAttribute('content', showError ? "404 - Page not found" : 'About us || Kaashika Wellness');
    return (
        <React.Fragment>
            <div className='PageStyle'>
            <div className='PageStyle'>
                        <Container>
                            <div className='AboutmeM'>
                                {
                                    cimage != null ? <img src={Globals.BASE_URL + '' + Globals.CONTENT_IMG_URL + '' + cimage} className="imgAvout" alt="" /> : ''
                                }
                                <h1>{cmsTitle}</h1>
                                <div dangerouslySetInnerHTML={{ __html: pContent }}></div>
                                <div className='clearfix '></div>
                            </div>

                        </Container>
                    </div>
            </div>
        </React.Fragment>
    )
}

import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
// import videoIcon from '../assets/images/play-button.png';
// import videoImg from '../assets/images/v.jpg';
// import videoImg2 from '../assets/images/v2.jpg';
// import videoImg3 from '../assets/images/v3.jpg';
// import videoImg4 from '../assets/images/v4.jpg';
// import videoImg5 from '../assets/images/v5.jpg';
// import videoImg6 from '../assets/images/v6.jpg';
import HomeVideo from './Home/HomeVideoList';
import axios from 'axios';
import Globals from '../common/Globals';
export default function Wellness() {
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [videos, setVideos] = useState([]);
    useEffect(() => {
        videosList();
    }, [])
    const videosList = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.VIDEO_LIST_URL, {}, {})
            .then(function (response) {
                // console.log(response.data);
                setVideos(response.data.videoList)
            })

    }
    document.title = "Kaashika Wellness";
    document.querySelector("meta[name='description']").setAttribute('content', 'Kaashika Wellness');
    document.querySelector("meta[name='keywords']").setAttribute('content', 'Kaashika Wellness');
    return (
        <React.Fragment>
            <div className='PageStyle'>
                <div className='LatestVideos mt-5 mb-5'>
                    <Container>
                        <h2>Wellness</h2>
                        {videos.length > 0 ? <HomeVideo items={videos} totalVideo={videos.length} /> : ''}
                    </Container>
                </div>
            </div>
        </React.Fragment>
    )
}

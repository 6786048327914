import React from 'react';
import { NavLink } from 'react-router-dom'
import { Nav, Form, Button } from 'react-bootstrap';
export default function NavBar(props) {
    return (
        <React.Fragment>
            <Nav className="ms-auto">
                <Nav.Link as={NavLink} to="/" exact={`true`} onClick={props.closeAction}>Home</Nav.Link>
                <Nav.Link as={NavLink} to="/about-us" onClick={props.closeAction}>About us</Nav.Link>
                <Nav.Link as={NavLink} to="/wllness" onClick={props.closeAction}>Wellness</Nav.Link>
                {/* <NavDropdown title="Wellness" id="basic-nav-dropdown">
                <NavDropdown.Item as={NavLink} to="/wllness">sub category 1</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/sub-category2"> sub category 2 </NavDropdown.Item>
                <NavDropdown.Item  as={NavLink} to="/sub-category3">sub category 3</NavDropdown.Item>
                </NavDropdown> */}
                <Nav.Link as={NavLink} to="testimonial" onClick={props.closeAction}>Testimonial</Nav.Link>
                {/* <Nav.Link as={NavLink} to="/contact-us" onClick={props.closeAction}>Contact us</Nav.Link> */}
            </Nav>
            <Form className="d-flex searchBox">
              <h4>Search Video</h4>
            <Form.Control
              type="search"
              placeholder="Search"
              className=""
              aria-label="Search"
            />
            <Button  onClick={props.closeAction} className='SearchBtn'><i className="bi bi-search"></i></Button>
          </Form>
        </React.Fragment>
    )
}


export default {
    COLOR: {
        ORANGE: '#C50',
        DARKBLUE: '#0F3274',
        LIGHTBLUE: '#6EA8DA',
        DARKGRAY: '#999',
    },
    BASE_URL: 'https://api.kaashika.com/',
    CONTENT_URL: 'content',
    CONTENT_IMG_URL: 'uploads/images/content/',
    CONTENT_PAGE_URL: 'content_page',
    HOME_BANNER_URL:'banner_video',
    HOME_WELCOME_BOTTOM_ADS_URL:'home_welcome_bottomads',
    HOME_VIDEO_URL:'home_videos',
    LATEST_VIDEO_URL:'latest_video',
    TESTIMONIAL_URL:'testimonials',
    VIDEO_DETAILS_URL:'videos_details',
    VIDEO_LIST_BY_GROUP_ID_URL:'group_videos',
    SETTING_URL:'website_settings', 
    VIDEO_LIST_URL:'video_list',
    SUBSCRIBE_NEWSLETTER_URL:'subscribe_newsletter',
    POST_TESTIMONIAL_URL:'addtestimonials',
};


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Container, Col, Row} from 'react-bootstrap';
import axios from 'axios';
import Globals from '../common/Globals';
import SubscribeNewsletter from './SubscribeNewsletter';
import TawkTo from 'tawkto-react'
import appLogo from '../assets/images/google-play.png';

// import telegram from '../assets/images/telegram.svg'
export default function Footer() {
    const [settings, setSettings] = useState([]);
    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.SETTING_URL, {}, {})
            .then(function (response) {
                // console.log(response.data);
                setSettings(response.data.websettings);
            })
    }

    useEffect(() => {
    
        var tawk = new TawkTo('64647b56ad80445890ed65c9', '1h0k83j9b')
    
        tawk.onStatusChange((status) => 
        {
            // console.log(status)
        })
    
    }, [])
    return (
        <React.Fragment>
            <div className='footerStyle'>
                <Container>
                    <Row>
                        <Col md="3" className='mb-4'>
                            <h4>Navigation</h4>
                            <ul>
                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="/page/terms-conditions">Terms of Service</Link></li>
                                <li><Link to="/page/privacy-policy">Privacy</Link></li>
                                {/* <li><Link to="/page/contact-us">Contact Us</Link></li> */}
                            </ul>
                            {/* <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.kaashika.kaashikawellness"}} target="_blank"><img src={appLogo} alt='Kaashika Wellness Download App' style={{height:42, marginTop:10}} /></Link> */}
                            <div className='d-flex align-items-center gap-2 mt-3'>
                            <a href='market://details?id=com.kaashika.kaashikawellness' target='_blank'><img src={appLogo} alt='Kaashika Wellness Download App' style={{height:42}} /></a>
                            <a href="share://me" style={{textDecoration:"none"}}><i className="bi bi-share"></i> Share</a>
                            </div>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <h4>Have a questions?</h4>
                            <ul className='questions'>
                                <li><i className="bi bi-geo-alt"></i> {settings.address}</li>
                                <li><i className="bi bi-telephone-forward"></i> <a href={`tel:${settings.mobile}`}>{settings.mobile}</a></li>
                                <li><i className="bi bi-envelope"></i> <a href={`mailto:${settings.email}`}>{settings.email}</a></li>
                            </ul>
                            <div className='siconStyle mt-4'>
                                <a href={settings.facebook_url} target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
                                <a href={settings.twitter_url} target='_blank' rel="noopener noreferrer"><i className="bi bi-twitter"></i></a>
                                <a href="https://wa.me/{settings.mobile}" target='_blank' rel="noopener noreferrer"><i className="bi bi-whatsapp"></i></a>
                                <a href={settings.youtube_url} target='_blank' rel="noopener noreferrer"><i className="bi bi-youtube"></i></a><br />
                                <a href={settings.instagram_url} target='_blank' rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
                                <a href={settings.telegram_url} target='_blank' rel="noopener noreferrer"><i className="bi bi-telegram"></i></a>
                                <a href={settings.telegram_url} target='_blank' rel="noopener noreferrer"><i className="bi bi-quora"></i></a>
                                <a href={settings.linkedin_url} target='_blank' rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a>
                            </div>
                        </Col>
                        <Col md="5" className='mb-4'>
                            <SubscribeNewsletter/>
                            {/* <h4>Subscribe to our newsletter.</h4>
                            <Form>
                                <Row>
                                    <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
                                        <Form.Control type="text" placeholder="Name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
                                        <Form.Control type="tel" placeholder="Mobile No." />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>
                                </Row>
                                <Button className='myBtn' type="submit">Submit</Button>
                            </Form> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}

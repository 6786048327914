import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// import Banner from "../assets/images/home.svg"
import Globals from '../common/Globals';
import PageNotFound from './PageNotFound';
export default function CmsPage() {
    const [showError, setpShowError] = useState(false);
    const [cmsTitle, setcmsTitle] = useState('');
    const [pContent, setpContent] = useState('');
    const params = useParams();
    useEffect(() => {
        getData();
    }, [params.pageName]);
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.CONTENT_PAGE_URL + '/' + params.pageName, {},
            {})
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status === 'ok') {
                    var count = Object.keys(response.data.cms).length;
                    if (count) {
                        // console.log(response.data.cms.pagename);
                        setcmsTitle(response.data.cms.pageheading);
                        setpContent(response.data.cms.pagecontent);
                    } else {
                        setpShowError(true);
                    }
                } else {
                    setpShowError(true);
                }
            })
    }
    document.title = showError ? "404 - Page not found" : cmsTitle;
    document.querySelector("meta[name='description']").setAttribute('content', showError ? "404 - Page not found" : 'About us || Kaashika Wellness');
    document.querySelector("meta[name='keywords']").setAttribute('content', showError ? "404 - Page not found" : 'About us || Kaashika Wellness');
    return (
        <React.Fragment>
            {
                showError ? <PageNotFound /> :
                    <div className='PageStyle'>
                        <Container>
                            <Row>
                                <Col md="12" className=' order-sm-1'>
                                    <div className='AboutmeM'>
                                        <h1>{cmsTitle}</h1>
                                        <div dangerouslySetInnerHTML={{ __html: pContent }}></div>
                                    </div>
                                </Col>
                                {/* <Col md="6"><img src={Banner} className="img-fluid" alt="" /></Col> */}
                            </Row>
                        </Container>
                    </div>
            }

        </React.Fragment>
    )
}

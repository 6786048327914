import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
// import userImg from '../assets/images/6.jpg';
import Globals from '../common/Globals';
export default function Testimonial() {
    const [testimonial, setTestimonial] = useState([]);
    // const [stdPhotoPlaceHolder, setstdPhotoPlaceHolder] = useState('');
    // const [defaultImg, setDefaultImg] = useState(userImg);
    var photoPlaceHolderText = '';
    var photoPlaceHolderTextArray = [];
    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.TESTIMONIAL_URL, {}, {})
            .then(function (response) {
                // console.log(response.data);
                setTestimonial(response.data.testimonials);
            })
    }
    const options = {
        margin: 10,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 2,
            }
        },
    };

    return (
        <React.Fragment>
            <Container className='testimonialStyle mt-5'>
                <div className='mb-5'>
                    <h4 className='text-center text-uppercase'>What <strong>Our Appraiser</strong> Are Saying</h4>
                </div>
                <OwlCarousel  {...options} item={3} className='owl-theme'>
                    {
                        testimonial.length > 0 ?
                            <>
                                {
                                    testimonial.filter((i, index) => (index < 10)).map((v, index) => {

                                        // setDefaultImg(v.user_image_url)

                                        photoPlaceHolderText = '';
                                        photoPlaceHolderTextArray = v.name.split(" ");
                                        if (photoPlaceHolderTextArray.length > 1) {
                                            photoPlaceHolderText = photoPlaceHolderTextArray[0].substring(0, 1) + '' + photoPlaceHolderTextArray[1].substring(0, 1);
                                        } else {
                                            photoPlaceHolderText = photoPlaceHolderTextArray[0].substring(0, 2);
                                        }
                                        // setstdPhotoPlaceHolder(photoPlaceHolderText);
                                        //console.log(v.user_image_url);
                                        
                                        // console.log(photoPlaceHolderText);
                                        return (
                                            <div className='item' key={index}>
                                                <div className='testMon'>
                                                    <Row className='align-items-center'>
                                                        <Col md="12" lg="2">
                                                            {/* <div className='avatarStyle'>{ photoPlaceHolderText }</div> */}
                                                            {(v.user_image_url!=='' && v.user_image_url!==null)?<div className='avatarStyle'><img src={v.user_image_url} alt=''/></div>:<div className='avatarStyle'>{ photoPlaceHolderText }</div>}
                                                        </Col>
                                                        <Col md="12" lg="10">
                                                            <div className='testmonialInn'>
                                                                <p>{v.testimonial}</p>
                                                                <h6>{v.name}</h6>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </> : ""
                    }
                    {/* <div className='item'>
                        <div className='testMon'>
                            <Row className='align-items-center'>
                                <Col className='col-auto '>
                                    <div className='avatarStyle'>{userImg ? <img src={userImg} alt="" /> : 'RS'}</div>
                                </Col>
                                <Col>
                                    <div className='testmonialInn'>
                                        <p>Lorem ipsum dolor sit amet, consec adipiscing elit. Nam eusem scelerisque tempor, varius quam luctus dui. Mauris magna metus nec.</p>
                                        <h6>MARTIN SOMMER, <span>UX Analyst</span></h6>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='testMon'>
                            <Row className='align-items-center'>
                                <Col className='col-auto '>
                                    <div className='avatarStyle'>{userImg ? <img src={userImg} alt="" /> : 'RS'}</div>
                                </Col>
                                <Col>
                                    <div className='testmonialInn'>
                                        <p>Lorem ipsum dolor sit amet, consec adipiscing elit. Nam eusem scelerisque tempor, varius quam luctus dui. Mauris magna metus nec.</p>
                                        <h6>MARTIN SOMMER, <span>UX Analyst</span></h6>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    */}
                </OwlCarousel>
            </Container>
        </React.Fragment>
    );
}

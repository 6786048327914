import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import Globals from '../common/Globals';

export default function PostTestimonial() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [testimonialMessage, setTestimonialMessage] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [testimonialMessageError, setTestimonialMessageError] = useState('');
    const [showLoading, setshowLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const submitTestimonialForm = (event) => {
        event.preventDefault(); // 👈️ prevent page refresh
        setshowLoading(true);
        setDisableButton(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile", mobile);
        formData.append("message", testimonialMessage);
        //console.log(formData);
        axios.post(Globals.BASE_URL + '' + Globals.POST_TESTIMONIAL_URL, formData)
            .then((res) => {
                setshowLoading(false);
                setDisableButton(false);
                // console.log(res);
                setNameError(res.data.error.name);
                setMobileError(res.data.error.mobile);
                setEmailError(res.data.error.email);
                setTestimonialMessageError(res.data.error.message);
                if (res.data.actionstatus === 'success') {
                    alert(res.data.message);
                    setName('');
                    setEmail('');
                    setMobile('');
                    setTestimonialMessage('');
                }

            })
            .catch((err) => console.log(err));
    };
    return (
        <>
            {/* <h4>Post Testimonial</h4> */}
            <Form className='testomonialForm'>
                <Row>
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        <span className='text-danger'>{nameError}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
                        <Form.Control type="tel" placeholder="Mobile No." value={mobile} onChange={(e) => setMobile(e.target.value)} />
                        <span className='text-danger'>{mobileError}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <span className='text-danger'>{emailError}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                        <Form.Control as="textarea" rows={3} placeholder="Enter Your message" value={testimonialMessage} onChange={(e) => setTestimonialMessage(e.target.value)} />
                        <span className='text-danger'>{testimonialMessageError}</span>
                    </Form.Group>
                </Row>
                <Row>
                    <Col><Button className='myBtn'  onClick={submitTestimonialForm} disabled={disableButton}>Submit</Button></Col>
                    <Col><span>{showLoading ? <Spinner animation="border" className='text-center' variant="primary" /> : ''}</span></Col>
                </Row>
            </Form>
        </>
    );
}

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import VideoItem from '../VideoItem';
export default function HomeVideo(props) {
    const [videos] = useState(props.items);
    const [videoCount] = useState(props.totalVideo);
    return (
        <React.Fragment>
            <Row>
                {
                    videoCount > 0 ?
                        <>
                            {
                                videos.map((v, pop) => {
                                    // console.log(v);
                                    return (
                                        <Col md="4" className='mb-4' key={pop}>
                                            <VideoItem video={v} />
                                        </Col>
                                    );
                                })}
                        </> : ""
                }
            </Row>
        </React.Fragment>
    );
}

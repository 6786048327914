import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
    // EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    // HatenaShareButton,
    // InstapaperShareButton,
    // LineShareButton,
    LinkedinShareButton,
    // LivejournalShareButton,
    // MailruShareButton,
    // OKShareButton,
    // PinterestShareButton,
    // PocketShareButton,
    // RedditShareButton,
    TelegramShareButton,
    // TumblrShareButton,
    // ViberShareButton,
    // VKShareButton,
    // WorkplaceShareButton
} from "react-share";
import Globals from '../common/Globals';
import RelatedVideos from './RelatedVideo';
export default function VideoDetails() {
    const [videoDeital, setVideoDeital] = useState([]);
    const params = useParams();
    // console.log(params);
    useEffect(() => {
        homeVideosList();
    }, [params.videoId])
    const homeVideosList = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.VIDEO_DETAILS_URL + '/' + params.videoId, {}, {})
            .then(function (response) {
                // console.log(response.data);
                setVideoDeital(response.data.videoDetail);
            })
    }
    return (
        <React.Fragment>
            <div className='videoDetails'>
                <Container>
                    <Row>
                        <Col sm="6">
                            <iframe src={videoDeital.youtube_embed_url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </Col>
                        <Col sm="6">
                            <h3>{videoDeital.name}</h3>
                            <h5 className='mt-3 mb-3'>{videoDeital.subtitle}</h5>
                            <p>{videoDeital.short_descriptions}</p>
                            <div className='d-flex gap-3 pagesiconStyle'>
                                <FacebookShareButton
                                    url={window.location.href}
                                    quote={videoDeital.name}
                                    hashtag="#muo"
                                >
                                    {/* <img src={facebook} alt="" /> */}
                                    <i className="bi bi-facebook"></i>
                                </FacebookShareButton>
                                <WhatsappShareButton
                                    url={window.location.href}
                                    quote={videoDeital.name}
                                    hashtag="#muo"
                                >
                                    <i className="bi bi-whatsapp"></i>
                                </WhatsappShareButton>
                                <TwitterShareButton
                                    url={window.location.href}
                                    quote={videoDeital.name}
                                    hashtag="#muo"
                                >
                                    <i className="bi bi-twitter"></i>
                                </TwitterShareButton>
                                <TelegramShareButton
                                    url={window.location.href}
                                    quote={videoDeital.name}
                                    hashtag="#muo"
                                >
                                   <i className="bi bi-telegram"></i>
                                </TelegramShareButton>
                                {/* <InstapaperShareButton
                                    url={window.location.href}
                                    quote={videoDeital.name}
                                    hashtag="#muo"
                                >
                                    <i className="bi bi-instagram"></i>
                                </InstapaperShareButton> */}
                                <LinkedinShareButton
                                    url={window.location.href}
                                    quote={videoDeital.name}
                                    hashtag="#muo"
                                >
                                    <i className="bi bi-linkedin"></i>
                                </LinkedinShareButton>
                            </div>

                        </Col>
                    </Row>
                    {/* <div  dangerouslySetInnerHTML={{ __html: pContent }}></div> */}
                    <div className='mt-5 mb-5 tabNavStyle'>
                        <h4 className='mb-3'>Description</h4>
                        <Tabs
                            defaultActiveKey="english"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="english" title="English">
                                <div dangerouslySetInnerHTML={{ __html: videoDeital.video_descriptions_english }}></div>
                            </Tab>
                            <Tab eventKey="hindi" title="हिंदी">
                                <div dangerouslySetInnerHTML={{ __html: videoDeital.video_descriptions_hindi }}></div>
                            </Tab>
                        </Tabs>
                    </div>
                    <RelatedVideos />
                </Container>
            </div>
        </React.Fragment>
    );
}

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
export default function PageNotFound() {
    return (
        <React.Fragment>
            <Container>
                <div className='text-center pt-5 pb-5 pageNot'>
                    <Row className='justify-content-md-center'>
                        <Col md='6'>
                            <h1>404</h1>
                            <h5>UH OH! You're lost.</h5>
                            <p>The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</p>
                            <Link to="/" className='myBtn'>Home</Link>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    );
}

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import videoImg from '../assets/images/v.jpg';
import videoImg2 from '../assets/images/v2.jpg';
import videoImg3 from '../assets/images/v3.jpg';
import videoImg4 from '../assets/images/v2.jpg';
import videoImg5 from '../assets/images/v3.jpg';
export default function PhotoGallery() {
    return (
        <React.Fragment>
            <div className='PageStyle'>
                <Container>
                    <div className='AboutmeM'>
                        <h1>Photo Gallery</h1>
                        <Row className='mt-4'>
                        <Col md="4" className='mb-4'>
                            <div className='gallerYBox h-100'>
                                <img src={videoImg} className="img-fluid" alt="" />
                            </div>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <div className='gallerYBox h-100'>
                                <img src={videoImg2} className="img-fluid" alt="" />
                            </div>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <div className='gallerYBox h-100'>
                                <img src={videoImg3} className="img-fluid" alt="" />
                            </div>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <div className='gallerYBox h-100'>
                                <img src={videoImg4} className="img-fluid" alt="" />
                            </div>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <div className='gallerYBox h-100'>
                                <img src={videoImg5} className="img-fluid" alt="" />
                            </div>
                        </Col>
                        
                    </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

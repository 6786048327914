import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Testimonial from './Testimonial';
import HomeBanner from './Home/HomeBannner';
import AboutHome from './Home/HomeAbout';
import HomeVideo from './Home/HomeVideoList';
import axios from 'axios';
import Globals from '../common/Globals';
import LatestHomeVideo from './Home/LatestVideoList';
// import addBanner from '../assets/images/add.png';
export default function Home() {
    const [homeVideos, setHomeVideos] = useState([]);
    const [homeWelcomeBottomAds, setHomeWelcomeBottomAds] = useState([]);

    useEffect(() => {
        homeVideosList();
        getHomeWelcomeBottomAds();
    }, [])
    const homeVideosList = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.HOME_VIDEO_URL, {}, {})
            .then(function (response) {
                //console.log(response.data);
                setHomeVideos(response.data.videoGroups)
            })

    }
    const getHomeWelcomeBottomAds = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.HOME_WELCOME_BOTTOM_ADS_URL, {}, {})
            .then(function (response) {
                //console.log(response.data);
                setHomeWelcomeBottomAds(response.data)
            })

    }

    document.title = "Kaashika Wellness";
    document.querySelector("meta[name='description']").setAttribute('content', 'Kaashika Wellness');
    document.querySelector("meta[name='keywords']").setAttribute('content', 'Kaashika Wellness');
    return (
        <React.Fragment>
            <div className='BannerStyle'>
                <Container>
                    <Row className='align-items-center'>
                        <Col md="5">
                            <AboutHome />
                        </Col>
                        <Col md="7">
                            <HomeBanner />
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                homeWelcomeBottomAds.ads_image_url !== '' ?
                    <Container>
                        <div className='text-center d-none d-lg-block'><img src={homeWelcomeBottomAds.ads_image_url} alt='' /></div>
                    </Container>
                    : ''
            }
            <div className='LatestVideos mt-5 mb-5'>
                <Container>
                    <h2>Latest Updates</h2>
                    <LatestHomeVideo />
                    {/* <div className='text-center d-none d-lg-block mt-4'><img src={addBanner} alt='' /></div> */}
                </Container>
                {
                    homeVideos.map((v, pop) => {
                        return (
                            v.videos.length > 0 ?
                                <Container key={pop}>
                                    <h2 className='mt-5'>{v.name}</h2>
                                    {(v.ads_position === 'top' && v.ads_image_url !== '') ? <div className='text-center d-none d-lg-block mt-4'>
                                        <img src={v.ads_image_url} alt='' />
                                    </div> : ''}
                                    <HomeVideo items={v.videos} totalVideo={v.videos.length} />
                                    {((v.ads_position === 'bottom' || v.ads_position === 'default') && v.ads_image_url !== '') ? <div className='text-center d-none d-lg-block mt-4'>
                                        <img src={v.ads_image_url} alt='' />
                                    </div> : ''}
                                </Container> : ''
                        );
                    })
                }
                <Testimonial />
            </div>

        </React.Fragment>
    )
}

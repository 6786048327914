import axios from 'axios';
import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import Globals from '../common/Globals';
import VideoItem from './VideoItem';
export default function RelatedVideos(props) {
    const [videoList, setVideoList] = useState([]);
    useEffect(() => {
        videosList();
    }, [])
    const videosList = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.VIDEO_LIST_BY_GROUP_ID_URL + '/1', {}, {})
            .then(function (response) {
                // console.log(response.data);
                setVideoList(response.data.groupVideos.videos);
            })
    }

    const options = {
        margin: 10,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1.4,
            },
            1000: {
                items: 4,
            }
        },
    };

    return (
        <>
            <div className='LatestVideos RelatedVideoStyle'>
                <h4>Related Videos</h4>
                <OwlCarousel  {...options} item={3} className='owl-theme owl-stage'>
                    {
                        videoList.map((v) => {
                            // console.log(v);
                            return (
                                <div key={v.id} className='item article-items'>
                                    <VideoItem video={v} />
                                </div>
                            );
                        })}
                        
                </OwlCarousel>
            </div>
        </>
    );
}

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { Container, Offcanvas, Navbar } from 'react-bootstrap';
import logo from "../assets/images/logo.png"
import logoDark from "../assets/images/logo-dark.png"
import NavBar from './NavBar';
export default function HeaderMenu() {
  const [menushow, setMenushow] = useState(false);

  const handleMenuclose = () => setMenushow(false);
  const handleMenushow = () => setMenushow(true);
  return (
    <React.Fragment>
      {/* <Navbar bg="light" variant="light" className='MenuStyle shadow'>
        <Container>
          <Navbar.Brand as={NavLink} to="/"><img src={logo} alt="Kaashika" className='LogoStyle' /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <NavBar />
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
          
      <Navbar bg="dark" variant="dark" expand="lg" className='MenuStyle shadow'>
      <Container>
        <Navbar.Brand as={NavLink} to="/"><img src={logo} alt="Kaashika" className='LogoStyle' /></Navbar.Brand>
        <Navbar.Toggle  onClick={handleMenushow} />
        <Navbar>
          <NavBar className="menubob" />
        </Navbar>
      </Container>
    </Navbar>

      <Offcanvas show={menushow} onHide={handleMenuclose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><img src={logoDark} alt="Kaashika" className='LogoStyle' /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* NavBar Start */}
          <div className='mobileMenu'>
          <NavBar closeAction={handleMenuclose} />
          </div>
          {/* NavBar end */}
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  )
}

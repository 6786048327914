import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Modal } from 'react-bootstrap';
import Globals from '../common/Globals';
import PostTestimonial from './PostTestimonial';

export default function TestimonialPage() {
    const [testimonial, setTestimonial] = useState([]);
    // const [stdPhotoPlaceHolder, setstdPhotoPlaceHolder] = useState('');
    // const [defaultImg, setDefaultImg] = useState(userImg);
    var photoPlaceHolderText = '';
    var photoPlaceHolderTextArray = [];
    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.TESTIMONIAL_URL, {}, {})
            .then(function (response) {
                // console.log(response.data);
                setTestimonial(response.data.testimonials);
            })
    }

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
    return (
        <div className='PageStyle testimonialStyle'>
            <Container>
                <Row>
                    <Col><h1>Testimonial</h1></Col>
                    <Col md="auto">
                    <Button  variant="primary" className='myBtn' onClick={handleShow}>Post Testimonial</Button>
                    </Col>
                </Row>

                <Row>
                    {
                        testimonial.length > 0 ?
                            <>
                                {
                                    testimonial.filter((i, index) => (index < 10)).map((v, index) => {
                                        // setDefaultImg(v.user_image_url)
                                        photoPlaceHolderText = '';
                                        photoPlaceHolderTextArray = v.name.split(" ");
                                        if (photoPlaceHolderTextArray.length > 1) {
                                            photoPlaceHolderText = photoPlaceHolderTextArray[0].substring(0, 1) + '' + photoPlaceHolderTextArray[1].substring(0, 1);
                                        } else {
                                            photoPlaceHolderText = photoPlaceHolderTextArray[0].substring(0, 2);
                                        }
                                        // setstdPhotoPlaceHolder(photoPlaceHolderText);

                                        // console.log(photoPlaceHolderText);
                                        return (
                                            <Col md="6" key={index}>
                                                <div className='testMon mt-3 mb-3'>
                                                    <Row className='align-items-center'>
                                                        <Col md="12" lg="2">
                                                        {(v.user_image_url!=='' && v.user_image_url!==null)?<div className='avatarStyle'><img src={v.user_image_url} alt=''/></div>:<div className='avatarStyle'>{ photoPlaceHolderText }</div>}
                                                        </Col>
                                                        <Col md="12" lg="10">
                                                            <div className='testmonialInn'>
                                                                <p>{v.testimonial}</p>
                                                                <h6>{v.name}</h6>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        );
                                    })}
                            </> : ""
                    }

                </Row>
                
                {/* Modal  */}
                <Modal size="md" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Post Testimonial</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><PostTestimonial /></Modal.Body>
                </Modal>
                {/* End */}
            </Container>
        </div>
    );
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import videoImg from '../assets/images/noThumbnail.jpg';
import videoIcon from '../assets/images/play-button.png';
import { Modal } from 'react-bootstrap';

export default function VideoItem(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };
    return (
        <React.Fragment>
            <div className='shadow vbox h-100'>
                <div className='vImg'>
                    <img src={props.video.thumbnail_image_url ? props.video.thumbnail_image_url : videoImg} className="img-fluid" alt="" />
                    <Link to="#" onClick={handleShow} className="banner-play-icon" title="Play Video"><img src={videoIcon} alt="" /></Link>
                </div>
                <h4>{props.video.name}</h4>
                <p>{props.video.subtitle}</p>
                <div className='d-flex gap-3'>
                    <Link to={`/video-detail/${props.video.id}`} className='myBtn'>Read More</Link>
                    {/* <Link to="/contact-us" className='myBtn myBtn2'>Contact us</Link> */}
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg" centered >
                <Modal.Header closeButton>
                    <Modal.Title>{props.video.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='viframe'>
                        <iframe width="560" height="315" src={props.video.youtube_embed_url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
